@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  font-family: 'Comfortaa', cursive;
}
.imageContainer{
  display: flex;
   justify-content: center
}
.formContainer {
  width: 50%; margin: 0px auto; height: 100vh
}
.formHeading {
  display: flex; justify-content: center
}
.formCheckBox {
  display: flex;
  justify-content: center;
  margin-top:20px
}
.formLoginContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px
}
.formLoginButton {
  background-color: #343d73; width: 300px
}
.formForgetPassword {
  display: flex;
  justify-content: center;
  margin-top:20px;
  cursor:pointer;
}
.formForgetPassword:hover{
  color: #4b6cb7;
}
.formStillNoAccount {
  display: flex;
  justify-content: center;
  margin-top: 20px
}
.formStillNoAccount span {
  padding-top: 12px
}
.formStillNoAccountButton{
  background-color: rgb(123 125 137);
  width: 150px;
  margin: 5px
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #4b6cb7;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: #395591;
}

.form span {
  font-size: 75px;
  color: #4b6cb7;
}
.heading{
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.icon{
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: space-around;
}
.title{
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: capitalize;
  text-align: center;
}
.btn{
  display: flex;
  justify-content: space-around;
}